<template>
    <div>
        <div>
            <Select v-model="officialCity" transfer style="width: 140px" placeholder="请选择市" filterable @on-change="val => getOfficialScopeList(infoId, '3', val)">
                <Option :value="item.orgCode" v-for="(item, index) in officialCityList" :key="index">{{ item.orgName }}</Option>
            </Select>
            <Select
                v-model="officialRegion"
                transfer
                style="width: 140px"
                placeholder="请选择区"
                @on-clear="clearRegion"
                clearable
                filterable
                @on-change="val => getOfficialScopeList(infoId, '4', val)"
            >
                <Option :value="item.orgCode" v-for="(item, index) in officialRegionList" :key="index">{{ item.orgName }}</Option>
            </Select>
            <Select
                clearable
                v-model="officialStree"
                transfer
                style="width: 140px"
                placeholder="请选择街道/乡镇"
                filterable
                @on-clear="clearStree"
                @on-change="val => getOfficialScopeList(infoId, '5', val)"
            >
                <Option :value="item.orgCode" v-for="(item, index) in officialStreeList" :key="index">{{ item.orgName }}</Option>
            </Select>
            <Select v-model="officialProject" transfer :label-in-value="true" style="width: 270px" :max-tag-count="2" placeholder="请选择社区" multiple filterable @on-change="changeProject">
                <Option :value="item.orgCode" v-for="(item, index) in officialProjectList" :key="index">{{ item.orgName }}</Option>
            </Select>
            <Button type="primary" style="margin: 0 10px" @click="makeOfficialQRCode">生成链接</Button>

            <Poptip placement="bottom-end" word-wrap width="500">
                <Icon type="md-help" />
                <div slot="content" style="text-indent: 2em">
                    <p>说明:</p>
                    <p>生成分享码: 是将选择的当前范围生成一个二维码进行推广分享(一张)</p>
                    <p>按社区生成分享码:是将选择的当前范围下包含的所有社区按照社区为</p>
                    <p>单位生成各自的社区二维码进行推广分享(多张)</p>
                    <p>二维码的样式与下载时选择的模板一致</p>
                </div>
            </Poptip>
        </div>
        <div v-if="codeImgUrl && officialUrl">
            <h3 style="margin-top: 10px">该链接可以用于配置到公众号进行推广:</h3>
            <div style="display: flex; justify-content: space-between; line-height: 32px; margin: 14px auto">
                <span>公众号链接：</span>
                <Input v-model="officialUrl" search enter-button="复制" style="flex: 1; margin-right: 100px" @on-search="copyShareCode"></Input>
            </div>
            <!-- <div
            style="
              display: flex;
              justify-content: space-between;
              line-height: 32px;
              margin: 14px auto;
            "
          >
            <span style="width:84px">短信链接：</span>
            <Input
              v-model="noteUrl"
              search
              enter-button="复制"
              style="flex: 1;margin-right: 100px"
              @on-search="copyNoteUrl"
            ></Input>
          </div> -->
            <h3>推广二维码已生成，可下载后直接分享二维码到微信（朋友、朋友圈）</h3>
            <div class="qrdiv">
                <div style="display: flex; align-items: center; justify-content: center; margin-right: 200px">
                    <div :class="!textActive ? 'qrcode qrcode-antoer' : 'qrcode'" ref="haibaoImg" :style="{ background: `url('${qrimg}') no-repeat` }">
                        <div class="h1h3">
                            <!-- <div class="h1h3 fontsize3">
                  <div> -->
                            <div
                                :class="
                                    qrOCdeName.length <= 8
                                        ? !textActive
                                            ? 'fontsize1 fontactive'
                                            : 'fontsize1'
                                        : qrOCdeName.length > 8 && qrOCdeName.length <= 30
                                        ? !textActive
                                            ? 'fontsize2 fontactive'
                                            : 'fontsize2'
                                        : !textActive
                                        ? 'fontsize3 fontactive'
                                        : 'fontsize3'
                                "
                            >
                                {{ qrOCdeName }}
                            </div>

                            <!-- <h3>入驻{{qrOCdeName}}</h3> -->
                        </div>
                        <!-- qrOCdeName -->
                        <span style="letter-spacing: 3px; z-index: 99">{{ qrCodeOrgName }}</span>
                        <img :src="codeImgUrl + '?' + new Date().getTime()" alt ref="qrCode" crossorigin="anonymous" style="z-index: 98" />
                    </div>
                    <div style="width: 130px; height: 100%; margin-left: 30px; margin-bottom: 480px">
                        <Button type="primary" style="margin-bottom: 20px; width: 130px" @click="downloadImgCode">下载纯二维码</Button>
                        <Button type="warning" @click="mergeImage">下载宣传二维码</Button>
                    </div>
                    <div :class="!textActive ? 'diyimg' : 'diyimg diyimg-right'">
                        <Progress v-if="file.showProgress" :percent="file.percentage" :stroke-width="5"></Progress>
                        <Upload
                            ref="imgUpload"
                            :before-upload="beforeUpload"
                            :format="['jpg', 'jpeg', 'png', 'gif']"
                            style="display: inline-block"
                            :on-format-error="imageError"
                            :on-progress="imageProgress"
                            :on-success="(value, file) => imageSuccess(file)"
                            :action="action"
                            :data="uploadData"
                            :on-remove="imageRemove"
                            :show-upload-list="false"
                        >
                            <Button icon="ios-cloud-upload-outline" type="info">自定义更换背景</Button>
                        </Upload>
                        <Button v-if="qrimg" type="success" @click="cropperFn" style="margin-left: 10px">裁剪图片</Button>
                        <div class="checkimg">
                            <div class="imglist">
                                <img @click="changeQrimgNo(item, index)" :class="qrimgNo == index + 1 ? 'active' : ''" v-for="(item, index) in qrimgList" :key="item" :src="item" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <img :src="codeImgUrl" alt width="200" style="display: block;margin-bottom: 10px" />
            <Button type="primary" @click="downloadImgCode">下载二维码</Button> -->
            </div>
        </div>
        <div
            v-if="!codeImgUrl && !officialUrl"
            style="
                height: calc(100vh - 305px);
                overflow: auto;
                margin: 20px;
                border-radius: 4px;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #ccc;
                color: #fff;
                font-size: 20px;
            "
        >
            可生成街道或者社区的推广链接和二维码
        </div>

        <!-- 分享二维码背景图片裁剪弹窗 -->
        <LiefengModal @input="cropperCancel" width="600px" height="500px" :value="cropperStatus">
            <template v-slot:contentarea>
                <div class="cropper-content" v-if="cropperStatus">
                    <div class="cropper" style="text-align: center">
                        <vueCropper
                            ref="cropper"
                            :img="option.img"
                            :outputSize="option.size"
                            :outputType="option.outputType"
                            :info="true"
                            :full="option.full"
                            :canMove="option.canMove"
                            :canMoveBox="option.canMoveBox"
                            :original="option.original"
                            :autoCrop="option.autoCrop"
                            :fixed="option.fixed"
                            :fixedNumber="option.fixedNumber"
                            :centerBox="option.centerBox"
                            :infoTrue="option.infoTrue"
                            :fixedBox="option.fixedBox"
                        ></vueCropper>
                    </div>
                </div>
            </template>
            <template v-slot:toolsbar>
                <Button type="info" @click="cropperCancel(false)" style="margin-right: 10px">取消</Button>
                <Button type="primary" @click="cropperFinish">裁剪</Button>
            </template>
        </LiefengModal>
    </div>
</template>

<script>
import html2canvas from "html2canvas"
import LiefengModal from "@/components/LiefengModal"

export default {
    components: {
        LiefengModal,
    },
    props: {
        infoId: String,
        qrOCdeName: String,
        codeString: String,
        functionType: String,
        bgColor: String,
        infoCode:String
    },
    data() {
        return {
            officialCity: "",
            officialCityList: [],
            officialRegion: "",
            officialRegionList: [],
            officialStree: "",
            officialStreeList: [],
            officialProject: "",
            officialProjectList: [],
            // 区/街道/社区名称
            qrCodeOrgName: "",
            changeVal: "",
            newProjectList: [],

            codeImgUrl: "",
            officialUrl: "",
            spinShow: false,
            isTure: false,

            // 短信链接
            noteUrl: "",
            noteCode: "",

            // 分享二维码图片列表
            qrimgList: [
                "/images/pic_hd1.png",
                "/images/pic_hd2.png",
                "/images/pic_hd3.png",
                "/images/pic_hd4.png",
                "/images/pic_hd5.png",
                "/images/pic_hd6.png"
            ],
            qrimgNo: "1",
            qrimg: "/images/pic_hd1.png",

            // 上传图片
            action: "",
            file: "",
            uploadData: {},
            imageName: "",
            imagePath: "",
            // 裁剪组件的基础配置option
            option: {
                img: "", // 裁剪图片的地址
                info: true, // 裁剪框的大小信息
                outputSize: 0.8, // 裁剪生成图片的质量
                outputType: "jpg", // 裁剪生成图片的格式
                canScale: true, // 图片是否允许滚轮缩放
                autoCrop: true, // 是否默认生成截图框
                autoCropWidth: 600, // 默认生成截图框宽度
                autoCropHeight: 500, // 默认生成截图框高度
                fixedBox: false, // 固定截图框大小 不允许改变
                fixed: true, // 是否开启截图框宽高固定比例
                fixedNumber: [400, 565], // 截图框的宽高比例
                full: true, // 是否输出原图比例的截图
                canMoveBox: true, // 截图框能否拖动
                original: false, // 上传图片按照原始比例渲染
                centerBox: true, // 截图框是否被限制在图片里面
                infoTrue: false, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
            },
            cropperStatus: false,

            textActive: false,
        }
    },
    methods: {
        // 下载纯二维码
        downloadImgCode() {
            var image = new Image()
            // 解决跨域 Canvas 污染问题
            image.setAttribute("crossOrigin", "anonymous")
            image.onload = function () {
                var canvas = document.createElement("canvas")
                canvas.width = image.width
                canvas.height = image.height
                var context = canvas.getContext("2d")
                context.drawImage(image, 0, 0, image.width, image.height)
                var url = canvas.toDataURL("image/png") //得到图片的base64编码数据
                var a = document.createElement("a") // 生成一个a元素
                var event = new MouseEvent("click") // 创建一个单击事件
                a.download = "公众号推广二维码"
                a.href = url // 将生成的URL设置为a.href属性
                a.dispatchEvent(event) // 触发a的单击事件
            }
            image.src = this.codeImgUrl
        },
        // 点击下载宣传二维码
        mergeImage() {
            html2canvas(this.$refs.haibaoImg, { useCORS: true }).then(function (canvas) {
                let src = canvas.toDataURL()
                var image = new Image()

                // 解决跨域 Canvas 污染问题
                image.setAttribute("crossOrigin", "anonymous")
                image.onload = function () {
                    canvas.width = image.width
                    canvas.height = image.height
                    var context = canvas.getContext("2d")
                    context.drawImage(image, 0, 0, image.width, image.height)
                    var url = canvas.toDataURL("image/png", 1.0)
                    var a = document.createElement("a")
                    var event = new MouseEvent("click")
                    a.download = "公众号宣传二维码"
                    a.href = url
                    a.dispatchEvent(event)
                }
                image.src = src
            })
        },
        // 点击复制按钮
        copyShareCode() {
            var oInput = document.createElement("input")
            oInput.value = this.officialUrl
            document.body.appendChild(oInput)
            oInput.select()
            document.execCommand("Copy")
            oInput.className = "oInput"
            oInput.style.display = "none"
            document.body.removeChild(oInput)
            this.$Message.success({
                background: true,
                content: "复制链接成功！",
            })
        },
        // 点击复制短信链接
        copyNoteUrl() {
            var oInput = document.createElement("input")
            oInput.value = this.noteUrl
            document.body.appendChild(oInput)
            oInput.select()
            document.execCommand("Copy")
            oInput.className = "oInput"
            oInput.style.display = "none"
            document.body.removeChild(oInput)
            this.$Message.success({
                background: true,
                content: "复制链接成功！",
            })
        },
        // 清空区码
        clearRegion() {
            // this.officialRegionList = []
            this.officialRegion = ""
            this.officialStree = ""
            this.officialProject = []
            this.qrCodeOrgName = ""
            this.changeVal = ""
        },
        // 点击清空街道按钮
        clearStree() {
            console.log("触发了清空事件")
            this.officialStree = ""
            this.officialProject = []
            this.qrCodeOrgName = ""
            this.changeVal = ""
        },
        // 点击生成链接按钮
        makeOfficialQRCode() {
            if (!this.officialCity) {
                this.$Message.error({
                    background: true,
                    content: "请至少选择一个范围",
                })
                return
            }
            this.$Message.loading({
                content: "正在生成链接",
                duration: 0,
            })
            this.spinShow = true
            this.isTure = false
            let params = {}
            if (this.officialCity && this.officialCity != "") {
                params = {
                    cityCode: this.officialCity,
                }
                this.officialCityList.map(item => {
                    if (item.orgCode == this.officialCity) {
                        this.qrCodeOrgName = item.orgName
                    }
                })
            }
            if (this.officialRegion && this.officialRegion != "") {
                params = {
                    regionCode: this.officialRegion,
                }
                this.officialRegionList.map(item => {
                    if (item.orgCode == this.officialRegion) {
                        this.qrCodeOrgName = item.orgName
                    }
                })
            }
            if (this.officialStree && this.officialStree != "") {
                params = {
                    streetCode: this.officialStree,
                }
                this.officialStreeList.map(item => {
                    if (item.orgCode == this.officialStree) {
                        this.qrCodeOrgName = item.orgName
                    }
                })
            }
            if (this.officialProject.length > 0) {
                this.qrCodeOrgName = ""
                let data = []
                params = {
                    communityList: (() => {
                        let result = []
                        this.officialProjectList.map(item => {
                            if (this.officialProject.indexOf(item.orgCode) != -1) {
                                result.push({
                                    projectCode: item.mappingCode,
                                    projectName: item.orgName,
                                })
                            }
                        })
                        return result
                    })(),
                }
                params.communityList.map(item => {
                    data.push(item.projectName)
                })
                this.qrCodeOrgName = data.join(",")
            }
            if (this.functionType && this.functionType == "17") {
                this.$post(
                    "/datamsg/api/pc/wxqr/createWxAppletQr",
                    {
                        type: "ACTIVITY_SHARE",
                        value: JSON.stringify({
                            path: "pages/welcome/welcome",
                            redirectPath: "/apps/suiyue/pages/answer/index",
                            params: {
                                ...params,
                                infoId: this.codeString,
                                functionType: this.functionType,
                                statusBarBackgroundColor: this.bgColor || "",
                                statusBarTextColor: this.bgColor && this.bgColor != "" ? "#ffffff" : "",
                            },
                        }),
                    },
                    { "Content-Type": "application/json" }
                ).then(res => {
                    if (res.code == 200 && res.data) {
                        this.codeImgUrl = res.data.codeUrl
                        this.officialUrl = "pages/welcome/welcome?scene=" + res.data.code
                        this.spinShow = false
                        this.noteCode = res.data.code
                    }
                })
            } else if (this.functionType == "24") {
                this.$post(
                    "/datamsg/api/pc/wxqr/createWxAppletQr",
                    {
                        type: "ACTIVITY_SHARE",
                        value: JSON.stringify({
                            path: "pages/welcome/welcome",
                            redirectPath: "/mainTaskPages/modules/activity/pages/detail/index",
                            params: {
                                ...params,
                                code: this.infoCode,
                            },
                        }),
                    },
                    { "Content-Type": "application/json" }
                ).then(res => {
                    if (res.code == 200 && res.data) {
                        this.codeImgUrl = res.data.codeUrl
                        this.officialUrl = "pages/welcome/welcome?scene=" + res.data.code
                        this.spinShow = false
                        this.noteCode = res.data.code
                    }
                })
            } else if (this.functionType == "25") {
                this.$post(
                    "/datamsg/api/pc/wxqr/createWxAppletQr",
                    {
                        type: "ACTIVITY_SHARE",
                        value: JSON.stringify({
                            path: "pages/welcome/welcome",
                            redirectPath: "/mainTaskPages/modules/infoCollection/pages/detail/index",
                            params: {
                                ...params,
                                code: this.infoCode,
                            },
                        }),
                    },
                    { "Content-Type": "application/json" }
                ).then(res => {
                    if (res.code == 200 && res.data) {
                        this.codeImgUrl = res.data.codeUrl
                        this.officialUrl = "pages/welcome/welcome?scene=" + res.data.code
                        this.spinShow = false
                        this.noteCode = res.data.code
                    }
                })
            } else {
                this.$post(
                    "/datamsg/api/pc/wxqr/createWxAppletQr",
                    {
                        type: "ACTIVITY_SHARE",
                        value: JSON.stringify({
                            path: "pages/welcome/welcome",
                            redirectPath: "/apps/suiyue/pages/information/sharingdetails/details",
                            params: {
                                ...params,
                                code: this.codeString,
                                functionType: this.functionType,
                            },
                        }),
                    },
                    { "Content-Type": "application/json" }
                ).then(res => {
                    if (res.code == 200 && res.data) {
                        this.codeImgUrl = res.data.codeUrl
                        this.officialUrl = "pages/welcome/welcome?scene=" + res.data.code
                        this.spinShow = false
                        this.noteCode = res.data.code
                    }
                })
            }

            this.$Message.destroy()
        },
        // 选择街道获取的数据
        changeProject(val) {
            this.newProjectList = val
        },
        // 获取层级数据
        getOfficialScopeList(informId, orgLevel, orgCodes) {
            if (!informId || (!orgCodes && (orgLevel == "4" || orgLevel == "5"))) return
            this.changeVal = orgCodes
            this.$get("/old/api/pc/information/getInformationScopeList", {
                informId: informId,
                orgLevel: orgLevel,
                orgCodes: orgCodes,
            })
                .then(res => {
                    if (res.code == 200 && res.dataList) {
                        if (orgLevel == "2") {
                            this.officialCityList = res.dataList
                            this.officialRegionList = []
                            this.officialRegion = ""
                            this.officialStreeList = []
                            this.officialStree = ""
                            this.officialProject = []
                            this.officialProjectList = []
                            if (res.dataList.length == 1) {
                                this.officialCity = res.dataList[0].orgCode
                                this.getOfficialScopeList(informId, "3", this.officialCity)
                            }
                        } else if (orgLevel == "3") {
                            this.officialRegionList = res.dataList
                            this.officialStreeList = []
                            this.officialStree = ""
                            this.officialProject = []
                            this.officialProjectList = []
                            if (res.dataList.length == 1) {
                                this.officialRegion = res.dataList[0].orgCode
                                this.getOfficialScopeList(informId, "4", this.officialRegion)
                            }
                        } else if (orgLevel == "4") {
                            this.officialStreeList = res.dataList
                            this.officialProject = []
                            this.officialProjectList = []
                            if (res.dataList.length == 1) {
                                this.officialStree = res.dataList[0].orgCode
                                this.getOfficialScopeList(informId, "5", this.officialStree)
                            }
                        } else {
                            this.officialProjectList = res.dataList
                            if (res.dataList.length == 1) {
                                this.officialProject.push(res.dataList[0].orgCode)
                            }
                        }
                    } else {
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                    this.$Message.destroy()
                })
                .catch(err => {
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败，请联系管理员处理",
                    })
                })
        },
        // 二维码背景选择
        changeQrimgNo(item, index) {
            this.qrimgNo = index + 1
            this.qrimg = `${item}`
            if (index >= 10) {
                this.textActive = true
            } else {
                this.textActive = false
            }
        },

        // 上传之前
        beforeUpload(file) {
            return this.$get("/datamsg/api/common/file/getWebPolicy?bucketName=liefeng").then(res => {
                if (res.code == 200) {
                    let now = this.$core.randomNumber()
                    //防止文件名有.
                    let suffixArr = file.name.split(".")
                    let suffix = "." + suffixArr[suffixArr.length - 1]
                    this.action =
                        process.env.NODE_ENV == "dev" || process.env.NODE_ENV == "development" ? "http://liefeng.oss-cn-shenzhen.aliyuncs.com" : "https://liefeng.oss-cn-shenzhen.aliyuncs.com"
                    this.uploadData = {
                        name: now + suffix,
                        host: res.data.host,
                        key: res.data.key + now + suffix,
                        policy: res.data.encodedPolicy,
                        OSSAccessKeyId: res.data.accessId,
                        success_action_status: "200",
                        signature: res.data.postSignature,
                    }
                    this.imageName = now + suffix
                    this.imagePath = res.data.host + res.data.key + this.imageName
                }
            })
        },
        //图片上传成功
        imageSuccess(file) {
            if (file.status == "finished") {
                this.qrimgNo = ""
                this.qrimg = this.imagePath
                this.$Message["success"]({
                    background: true,
                    content: "图片上传成功！",
                })
            } else {
                this.$Message["error"]({
                    background: true,
                    content: "图片上传失败！",
                })
            }
        },
        // 图片格式错误
        imageError(file) {
            this.$Notice.warning({
                title: "上传文件格式错误！",
                desc: "文件 " + file.name + " 上传失败, 请上传jpg, jpeg, png或gif格式的图片！",
            })
        },
        // 图片上传进度
        imageProgress(event, file, fileList) {
            this.file = file
            event.target.onprogress = event => {
                let uploadPercent = parseFloat(((event.loaded / event.total) * 100).toFixed(2)) // 保留两位小数
                // 手动设置显示上传进度条 以及上传百分比
                this.file.showProgress = true
                this.file.percentage = uploadPercent
            }
        },
        // 图片移除
        imageRemove(file) {
            this.qrimg = ""
            this.file = {}
        },
        //取消裁剪
        cropperCancel(status) {
            this.cropperStatus = status
        },
        //打开裁剪
        async cropperFn() {
            console.log(111)
            if (!this.uploadData.host) {
                console.log(222)

                await this.$get("/datamsg/api/common/file/getWebPolicy?bucketName=liefeng").then(res => {
                    if (res.code == 200) {
                        let now = this.$core.randomNumber()
                        //防止文件名有.
                        let suffixArr = this.qrimg.split(".")
                        let suffix = "." + suffixArr[suffixArr.length - 1]
                        this.action =
                            process.env.NODE_ENV == "dev" || process.env.NODE_ENV == "development" ? "http://liefeng.oss-cn-shenzhen.aliyuncs.com" : "https://liefeng.oss-cn-shenzhen.aliyuncs.com"
                        this.uploadData = {
                            name: now + suffix,
                            host: res.data.host,
                            key: res.data.key + now + suffix,
                            policy: res.data.encodedPolicy,
                            OSSAccessKeyId: res.data.accessId,
                            success_action_status: "200",
                            signature: res.data.postSignature,
                        }
                        this.imageName = now + suffix
                        this.imagePath = this.uploadData.host + this.uploadData.key
                    }
                })
            }
            let _this = this
            this.setAvatarBase64(this.qrimg, base64 => {
                _this.option.img = base64
            })
            this.cropperStatus = true
        },
        //确定裁剪
        cropperFinish() {
            this.$refs.cropper.getCropBlob(data => {
                this.$refs.imgUpload.clearFiles()
                let formData = new FormData()
                formData.append("name", this.uploadData.name)
                formData.append("host", this.uploadData.host)
                formData.append("key", this.uploadData.key)
                formData.append("policy", this.uploadData.policy)
                formData.append("OSSAccessKeyId", this.uploadData.OSSAccessKeyId)
                formData.append("success_action_status", this.uploadData.success_action_status)
                formData.append("signature", this.uploadData.signature)
                formData.append("file", data)
                this.$post("https://liefeng.oss-cn-shenzhen.aliyuncs.com", formData, {
                    "Content-Type": "multipart/form-data",
                }).then(res => {
                    this.$Message.success({
                        background: true,
                        content: "裁剪完成",
                    })
                    this.qrimg = ""
                    this.$nextTick(() => {
                        this.qrimg = this.imagePath + "?" + new Date().getTime()
                        this.cropperCancel(false)
                    })
                })
            })
        },
        // 设置图片base64
        setAvatarBase64(src, callback) {
            let _this = this
            let image = new Image()
            // 处理缓存
            image.src = src + "?v=" + Math.random()
            // 支持跨域图片
            image.crossOrigin = "*"
            image.onload = function () {
                let base64 = _this.transBase64FromImage(image)
                callback && callback(base64)
            }
        },
        // 将网络图片转换成base64格式
        transBase64FromImage(image) {
            let canvas = document.createElement("canvas")
            canvas.width = image.width
            canvas.height = image.height
            let ctx = canvas.getContext("2d")
            ctx.drawImage(image, 0, 0, image.width, image.height)
            // 可选其他值 image/jpeg
            return canvas.toDataURL("image/png")
        },
    },
    filters: {
        spliceTitle(option) {
            if (option.length > 22) {
                return (option = option.slice(0, 22) + "...")
            } else {
                return option
            }
        },
    },
}
</script>

<style lang="less" scoped>
// 截图
.cropper-content {
    .cropper {
        width: 580px;
        height: 428px;
    }
}
.ivu-poptip {
    background: black;
    padding: 0 4px;
    border-radius: 50%;
    .ivu-icon-md-help {
        color: #fff;
    }
}
.diyimg {
    position: absolute;
    right: 162px;
    bottom: 286px;
    width: 380px;
    height: 180px;
    .imglist {
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 310px;

        img {
            width: 60px;
            margin-right: 10px;
            margin-bottom: 10px;
            cursor: pointer;
        }
        .active {
            // border: 2px solid red;
            box-shadow: 0 0 5px blue;
            box-sizing: border-box;
        }
    }
}
.diyimg-right {
    right: 131px;
}
.qrdiv {
    position: relative;
    .qrcode {
        position: relative;
        width: 400px;
        height: 565px;
        background: url("/images/pic_hd1.jpg") no-repeat;
        background-size: 100% 100% !important;
        img {
            position: absolute;
            top: 235px;
            left: 90px;
            width: 220px;
        }

        .h1h3 {
            width: 260px;
            height: 119px;
            // background-color: #333333;
            // color: #FF0000;
            color: #333;
            // width: 100%;
            position: absolute;
            top: 75px;
            left: 70px;
            text-align: center;
            h1 {
                width: 59%;
                margin: 0 auto;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }
        .fontsize1 {
            width: 100%;
            font-size: 26px;
            line-height: 119px;
            word-break: break-all;
        }
        .fontsize2 {
            width: 100%;
            box-sizing: border-box;
            font-size: 24px;
            line-height: 33px;
            word-break: break-all;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        .fontsize3 {
            width: 100%;
            font-size: 20px;
            word-break: break-all;
            line-height: 30px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        .fontactive {
            margin-top: 50px;
        }
        h1 {
            text-align: left;
            font-size: 26px;
            color: red;
            margin-bottom: 10px;
            line-height: 36px;
        }
        h3 {
            padding: 0 30px;
            text-align: center;
            font-size: 26px;
            color: #c62e23;
        }
        p {
            width: 100%;
            position: absolute;
            top: 201px;
            text-align: center;
            font-size: 15px;
            color: #666666;
        }
        span {
            width: 70%;
            position: absolute;
            text-align: center;
            top: 470px;
            font-size: 18px;
            color: #333;
            margin: 0 15%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .qrcode-antoer {
        width: 334px;
        height: 544px;
        .h1h3 {
            left: 35px;
        }
        img {
            left: 55px;
        }
    }
}
</style>